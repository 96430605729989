import React from "react"
import { graphql, Link } from "gatsby"
import Image from "../components/Image"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import Text from "../components/Text"
import { Accordion } from "../components/Accordion"

const ProceduresQueryPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  post.groups.map(g => {
    if (g.category === "general") {
      g.services = data.generalServices.nodes
    }

    if (g.category === "cosmetic") {
      g.services = data.cosmeticServices.nodes
    }
    if (g.category === "restorative") {
      g.services = data.restorativeServices.nodes
    }
  })

  const [topGroup, ...restGroups] = post.groups

  let schemaData
  if (language === "en") {
    schemaData = {
      presets: post.schemas.presets,
      customSchema: post.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaDescription: post.metaDescription,
      path: location.pathname,
      productontologyRelations: post.productontologyRelations
    }
  }

  return (
    <SharedStateProvider>
      <Layout language={language} pageHeading={post.heading}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          schemaData={schemaData}
          pathname={location.pathname}
        />
        <div className="procedure-query">
          <Section smallSpacing>
            <Columns sideColumnsSize="3">
              <div className="column">
                <Text text={post.topBlurb} as="p" />
              </div>
            </Columns>
          </Section>

          <Section className="mt-0">
            <Columns sideColumnsSize="3">
              <div
                className="column"
                id={topGroup.heading.split(" ").join("-").toLowerCase()}>
                {/* <Text text={topGroup.heading} as="h2" /> */}
                {/* <Text text={topGroup.blurb} as="p" /> */}
                <div className="procedure-query__services">
                  {topGroup.services.map((s, i) => (
                    <ServiceCard
                      key={i}
                      href={s.title}
                      image={s.query.queryImage}
                      heading={s.query.heading}
                      blurb={s.query.blurb}
                    />
                  ))}
                </div>
              </div>
            </Columns>
          </Section>

          {/* <Section smallSpacing>
            <Columns sideColumnsSize="3">
              <div className="column">
                {restGroups.map((group, i) => (
                  <Accordion
                    key={i}
                    visibleText={`## ${group.heading}\n\n${group.blurb}`}>
                    <div className="procedure-query__services">
                      {group.services.map((s, i) => (
                        <ServiceCard
                          key={i}
                          href={s.title}
                          image={i <= 2 ? s.query.queryImage : null}
                          heading={s.query.heading}
                          blurb={s.query.blurb}
                        />
                      ))}
                    </div>
                  </Accordion>
                ))}
              </div>
            </Columns>
          </Section> */}
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

const ServiceCard = ({ image, heading, blurb, href }) => {
  return (
    <div className="procedure-query__card">
      {image && (
        <Link to={`/${href}`} title={`Learn More About ${heading}`}>
          <Image publicId={image} />
        </Link>
      )}
      <div>
        <Link to={`/${href}`} title={`Learn More About ${heading}`}>
          <Text text={heading} as="h3" className={!image ? "mt-0" : null} />
        </Link>
        <Text text={blurb} as="p" />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query proceduresQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        groups {
          category
          heading
          blurb
        }
        productontologyRelations
        schemas {
          presets
          custom
        }
      }
    }
    generalServices: allProceduresJson(
      filter: { category: { eq: "general" }, query: { isHidden: { ne: true } } }
      sort: { fields: order }
    ) {
      nodes {
        category
        title
        query {
          blurb
          heading
          queryImage
        }
      }
    }
    cosmeticServices: allProceduresJson(
      filter: {
        category: { eq: "cosmetic" }
        query: { isHidden: { ne: true } }
      }
      sort: { fields: order }
    ) {
      nodes {
        category
        title
        query {
          blurb
          heading
          queryImage
        }
      }
    }
    restorativeServices: allProceduresJson(
      filter: {
        category: { eq: "restorative" }
        query: { isHidden: { ne: true } }
      }
      sort: { fields: order }
    ) {
      nodes {
        category
        title
        query {
          blurb
          heading
          queryImage
        }
      }
    }
  }
`

export default ProceduresQueryPage
